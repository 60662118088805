$(document).ready(function(){

  const breakpoints = {
    sm: 425,
    md: 960,
    lg: 1200,
    xl: 1500,
  }

  // NAVBAR MODAL
  
  $('.js-toggle-navbar, .navbar-modal-close').on('click',function(e){
    e.preventDefault();
    $('#navbar-modal').toggleClass('visible');
  });

  // MODALS

  $('.mfp-trigger').magnificPopup({
    type:'inline',
    showCloseBtn: false,
  });
  $('.modal-close').click(function(){
    $.magnificPopup.close();
  })

  // SLIDERS

  const swiperPartners = new Swiper('.swiper.partners', {
    loop: true,
    navigation: {
      prevEl: '.swiper-nav-prev',
      nextEl: '.swiper-nav-next',
    },
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      960: {
        slidesPerView: 6,
        spaceBetween: 32
      }
    }
  });
  const swiperNews = new Swiper('.swiper.news', {
    loop: true,
    navigation: {
      prevEl: '.swiper-nav-prev',
      nextEl: '.swiper-nav-next',
    },
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      960: {
        slidesPerView: 3,
        spaceBetween: 32
      },
    }
  });

  // PROJECTS
  $('.js-toggle-expand').each(function() {
    
    let $pointer = $(this);
    let $parent = $pointer.parents('.projects-list-item');

    $pointer.click(function(){
      $parent
        .toggleClass('active');
        // .siblings('.projects-list-item')
        // .removeClass('.active');
    })
  })

})